/*  global Component */
class CenterCostActionsComponent extends Component {

    static name() {
        return "centerCostActionsComponent";
    }

    static componentName() {
        return "centerCostActionsComponent";
    }

    getProps() {
        return  ['data', 'index', 'column'];
    }

    getComputed() {
        return  {
            session: function () {
                return this.$store.getters.getSession;
            },
            currentCustomerPerson: function () {
                return this.$store.getters.getCurrentCustomerPerson;
            },
        };
    }

    getMethods() {
        return {
            editRecord:this.editRecord,
            removeRecord:this.removeRecord
        };
    }

    async editRecord(){
        let oppenRecord = await axios.get('/ecommerce/api/getCenterCost?detail='+this.data.Code)
        //console.log('Edit CC', this.data,oppenRecord);
        this.emitEvent("editCenterCost",oppenRecord.data.centerCost);
    }

    removeRecord(){
        this.emitEvent("deleteCenterCost",this.data);
    }

    getTemplate() {
        return `<div :key="'cca-'+data.Code" class="custom-actions">
                  <a :key="'edit-ccb-'+data.Code"  :title="tr('Edit')" class="col-sm-5" data-toggle="modal" data-target="#centerCostModal"  @click="editRecord">
                     <i class="fas fa-edit"></i>
                  </a>
                  <a :key="'delete-ccb-'+data.Code"  :title="tr('Delete')" class="col-sm-5" @click="removeRecord">
                    <i class="fas fa-trash"></i>
                  </a>
               </div>`;
    }
}

CenterCostActionsComponent.registerComponent();
